import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import HorizontalCard from '../components/index/horizontal-card'
import TextContainer from '../components/ui-components/containers/text-container'
import UserCard from '../components/team/user-card'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      schlitten: file(relativePath: { eq: "kind_guckt_zu_schlitten.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      kinder: file(relativePath: { eq: "süße_kinder.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={'Das ShineOn Team'} />
      <TextContainer>
        <div className="mt-12 overflow-hidden">
          <Img
            fluid={data.schlitten.childImageSharp.fluid}
            alt=""
            className="rounded-lg h-90 flex items-start justify-start"
          />
        </div>
      </TextContainer>

      <TextContainer>
        <p className="pt-12">
          Deutschland ist eines der reichsten Länder der Welt –
          <strong> trotzdem leben über 2,55 Millionen Kinder in Armut</strong>.
          Diese Kinder müssen bereits das ganze Jahr über
          <strong> verzichten</strong>: Sei es auf Kleidung, die nicht bereits
          vom großen Bruder getragen wurde oder mehrere Löcher hat; auf
          Taschengeld, um sich selbst am Kiosk mal eine bunte Tüte zu kaufen;
          auf den Sportverein, weil das Geld der Eltern nicht für die
          monatlichen Mitgliedsbeiträge reicht; auf Kino- oder Zoobesuche –
          alles Dinge, die für uns selbstverständlich sind. Stellen Sie sich
          vor, Sie trauen sich nicht zur Geburtstagsfeier Ihres Freundes, weil
          Sie sich kein Geschenk für ihn leisten können. All das ist Realität
          und schafft soziale Ausgrenzung.
        </p>
        <p className="pt-4">
          Jedes Jahr vor Weihnachten stellt sich den Eltern dann wieder dieselbe
          Frage: Wie soll ich meinem Kind, das es das restliche Jahr über schon
          so wundervoll meistert, immer und immer wieder verzichten zu müssen,
          ein Weihnachtsgeschenk finanzieren? Wenigstens das eine Geschenk,
          damit es sich an Weihnachten genauso glücklich fühlen kann, wie alle
          anderen Kinder auch. Ich denke, jeder kann sich die innere
          Verzweiflung vorstellen, die eine Mutter oder ein Vater in solchen
          Momenten fühlt.
        </p>
        <p className="py-4">
          Zudem muss man sich klarmachen, dass „das eine Geschenk“ meist keine
          teure Spielekonsole o.ä. ist, sondern nur ein Fußball oder eine
          einzige, dringend benötigte Jacke – also etwas, das für uns andere
          erschwinglich ist und definitiv nicht etwas, wofür wir ein ganzes Jahr
          sparen müssten.
        </p>
        <p>
          Ich selbst komme aus einer Familie, in der es nie darum ging,
          <strong> ob</strong> ich etwas zu Weihnachten bekomme, sondern nur
          <strong> wie viel</strong> es wird. Freunden von mir ging es nicht so
          gut; auf sie traf eher die obige Beschreibung zu. Man selbst bekam
          also „mehr als genug“, während andere darauf hoffen mussten, überhaupt
          etwas geschenkt zu bekommen.
        </p>
        <p className="mt-4">
          Aus dieser persönlichen Erfahrung heraus entstand die Idee zu
          <strong> Shine On</strong>. Der eigentliche Gedanke hinter Weihnachten
          ist nicht der Konsum und damit möglichst viel zu schenken. Weihnachten
          steht für
          <strong> Nächstenliebe, für Solidarität, für Dankbarkeit.</strong> Für
          ein schönes <strong>Beisammensein.</strong>
        </p>
        <p className="mt-4">
          Wenn ich also das <strong>Glück </strong> habe, nicht in Armut zu
          leben, privilegiert zu sein – warum sollte ich dieses
          <strong>Glück </strong> dann nicht <strong>teilen</strong>? Was könnte
          es Schöneres geben?
        </p>
        <p className="py-8 text-center text-xl">
          <strong className="font-handwritten">
            „Glück ist das Einzige, was sich verdoppelt, wenn man es teilt“
          </strong>
          – das ist ein zentraler Grundsatz von Shine On.
        </p>

        <div className="mt-12 overflow-hidden pb-12">
          <Img
            fluid={data.kinder.childImageSharp.fluid}
            alt=""
            className="rounded-lg h-90 flex items-start justify-start"
          />
        </div>
      </TextContainer>

      {/**
      <TextContainer>
        <div className="mt-24 pb-8 flex justify-between flex-col">
          <UserCard name="Manu" reverse={false}></UserCard>
          <UserCard name="Jeanine" reverse={true}></UserCard>
        </div>
      </TextContainer>
      */}
    </Layout>
  )
}

export default Team
